<template>
<div id="app">

    <v-row>

  <v-col cols="5" lg="2">
    <v-menu v-model="menu" :close-on-content-click="false">
      <v-text-field
        :value="date_debut"
        slot="activator"
        v-model="date_debut"
        prepend-icon="mdi-calendar"
        clearable label="Date debut"
      ></v-text-field>
        <v-v-date-picker v-model="date_debut" @change="menu = false" ></v-v-date-picker>
    </v-menu>

  </v-col>
  <v-col cols="5" lg="2">
    <v-menu>
      <v-text-field
        :value="date_fin"
        slot="activator"
        v-model="date_fin"
        prepend-icon="mdi-calendar"
        label="Date fin"
      ></v-text-field>
        <v-v-date-picker v-model="date_fin" ></v-v-date-picker>
    </v-menu>

  </v-col>
  <v-col>
      <v-btn color="primary" dark class="mb-2" @click="readAll">
          Recherche
      </v-btn>

  </v-col>


  <v-col v-if="null">
      <v-btn color="green" dark class="mb-2" @click="Actulisation()">
          Actualiser
      </v-btn>

  </v-col>

    </v-row>

    <v-data-table :headers="headers" :items="list" class="elevation-1" color="primary">
        <template v-slot:top>
            <v-toolbar flat>
                <v-toolbar-title>LISTE DES COMMISSIONS CLIENTS</v-toolbar-title>
                <v-spacer></v-spacer>

                <v-dialog v-model="dialog_status" max-width="500px">
                    <v-card>
                        <v-card-title class="text-h5">Status paiement</v-card-title>
                        <v-card-text>
                          <v-select :items="list_type_status" v-model="selecttype_status" @change="onWantingStatusChange()"   dense outlined label="Status depot">
                          </v-select>

                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="blue darken-1" text @click="dialog_status=false">Annuller</v-btn>
                            <v-btn color="blue darken-1" text @click="update_wanting_statut()">Confirmer</v-btn>
                            <v-spacer></v-spacer>
                        </v-card-actions>
                    </v-card>
                </v-dialog>

                <v-dialog v-model="dialog_wanting_adress" max-width="400">
                  <v-card>
                      <v-toolbar flat color="blue">
                          <v-toolbar-title >Mise à jour adresse retrait</v-toolbar-title>
                          <v-spacer></v-spacer>
                          <v-btn icon dark @click="dialog_wanting_adress = false">
                              <v-icon>mdi-close</v-icon>
                          </v-btn>
                      </v-toolbar>
                      <v-card-text>
                          <v-col cols="12">
                              <v-text-field v-model="maj_wanting_adress" label="Adresse" outlined>

                              </v-text-field>
                          </v-col>

                      </v-card-text>
                      <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn color="green" dark @click="updateWantingAdresse()">Enregistrer</v-btn>
                          <v-spacer></v-spacer>
                      </v-card-actions>
                  </v-card>
                </v-dialog>

                <v-dialog v-model="dialog_wanting_money" max-width="400">
                  <v-card>
                      <v-toolbar flat color="blue">
                          <v-toolbar-title >Mise à jour Monnaie Retrait</v-toolbar-title>
                          <v-spacer></v-spacer>
                          <v-btn icon dark @click="dialog_wanting_money = false">
                              <v-icon>mdi-close</v-icon>
                          </v-btn>
                      </v-toolbar>
                      <v-card-text>
                          <v-col cols="12">
                              <v-text-field v-model="maj_wanting_money" label="Monnaie" outlined>

                              </v-text-field>
                          </v-col>

                      </v-card-text>
                      <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn color="green" dark @click="updateWantingMoney()">Enregistrer</v-btn>
                          <v-spacer></v-spacer>
                      </v-card-actions>
                  </v-card>
                </v-dialog>

            </v-toolbar>
        </template>



        <template v-slot:item.wanting_statut="{ item }">
            <div class="d-flex justify-center">

                <v-chip :color="item.wanting_statut =='CONFIRMED'?'green':'red'" dark>
                    {{ item.wanting_statut== null?'PENDING':item.wanting_statut}}
                </v-chip>

                <v-btn icon @click="wanting_status(item)">
                  <v-icon color="teal" large class="mr-2">
                      mdi-pencil
                  </v-icon>
                </v-btn>

            </div>

        </template>

        <template v-slot:item.wanting_adresse="{ item }">
            <div class="d-flex justify-center">

                <!-- put the wanting_adresse in p -->
                <p>{{ item.wanting_adresse }}</p>

                <v-btn icon @click="wanting_adress_update(item)">
                  <v-icon color="teal" large class="mr-2">
                      mdi-pencil
                  </v-icon>
                </v-btn>

            </div>

        </template>


        <template v-slot:item.wanting_currencie="{ item }">
            <div class="d-flex justify-center">

                <!-- put the wanting_adresse in p -->
                <p>{{ item.wanting_currencie }}</p>

                <v-btn icon @click="wanting_money_update(item)">
                  <v-icon color="teal" large class="mr-2">
                      mdi-pencil
                  </v-icon>
                </v-btn>

            </div>

        </template>




        <template v-slot:[`item.actions`]="{ item }">
            <v-btn icon @click="detail(item)">
                <v-icon color="teal" large class="mr-2">
                    mdi-eye
                </v-icon>
            </v-btn>

        </template>

    </v-data-table>

</div>
</template>

<script>
//import api from '../../../serviceApi/apiService'
import api from '@/serviceApi/apiService'

export default {
    name: "Gestion des clients",
    data: () => ({
        dialog_status: false,
        dialog_wanting_adress: false,
        dialog_wanting_money: false,
        maj_wanting_adress: '',
        maj_wanting_money: '',
        date_debut: null,
        date_fin: null,
        headers: [

            {

               text: "Date operation",
               align: "start",
               sortable: false,
               value: "operation_date",
               divider: true,
               width: '150px'
           },

            {
                text: "Monnaie de retrait",
                align: "start",
                sortable: false,
                value: "wanting_currencie",
                divider: true,
                width: '250px'
            },

            {
                text: "Montant de retrait",
                align: "start",
                sortable: false,
                value: "amount",
                divider: true,
                width: '100px'
            },

            {
                text: "Type de monnaie",
                align: "start",
                sortable: false,
                value: "wanting_type",
                divider: true,
                width: '100px'
            },

            {
                text: "Adresse de retrait",
                align: "start",
                sortable: false,
                value: "wanting_adresse",
                divider: true,
                width: '150px'
            },

            {
                text: "Network",
                align: "start",
                sortable: false,
                value: "wantingNetwork",
                divider: true,
                width: '50px'
            },

            {
                text: "Pays",
                value: "pays",
                divider: true,
                width: '10px'
            },

            {
                text: "ID Utilisateur",
                value: "id_user",
                divider: true,
                width: '10px'
            },

            {
                text: "Status de paiement",
                value: "wanting_statut",
                divider: true,
                width: '10px'
            },

        ],
        list: [],
        list_type_status: ['PENDING', 'CONFIRMED', 'FAILED'],
        selecttype_status: '',
        id_transaction: '',
        wanting_statut: '',
    }),

    created() {
          let today = new Date();
          let dd = today.getDate();
          let mm = today.getMonth()+1; //January is 0!
          let yyyy = today.getFullYear();
          this.date_debut = yyyy+'-'+mm+'-'+dd;
          this.date_fin = yyyy+'-'+mm+'-'+dd;
        this.readAll();
    },
    methods: {

        readAll: async function () {
            let fd = new FormData();
            fd.append('date_debut', this.date_debut);
            fd.append('date_fin', this.date_fin);
            const data = await api.createUpdatedata('backoffice/all-transaction-commission', fd);
            this.list = data;

        },


        update_wanting_statut: async function () {
            let fd = new FormData();
            fd.append('id', this.id_transaction);
            fd.append('wanting_statut', this.wanting_statut);
            console.log(this.wanting_statut);
            console.log(this.id_transaction);
             const data = await api.createUpdatedata('backoffice/comm-status-wanting', fd);
             this.dialog_status = false;
            if (data.status == true) {
              api.sendTelegram('Transaction ['+this.id_transaction+'] updated with new wanting status ('+this.wanting_statut+')');
                this.readAll();
                this.showAlert(data.message, 'success');
                this.selecttype_status2 = '';
            } else if (data.status == false) {
                this.showAlert(data.message, 'error');
            }
        },

        updateWantingAdresse: async function () {
          let fd = new FormData();
          fd.append('id', this.id_transaction);
          fd.append('wanting_adresse', this.maj_wanting_adress);
          const data = await api.createUpdatedata('backoffice/comm-update-wanting-adresse', fd);
          this.dialog_wanting_adress = false;
          if (data.status == true) {
              api.sendTelegram('Transaction ['+this.id_transaction+'] updated with new wanting adresse ('+this.maj_wanting_adress+')');
              this.readAll();
              this.showAlert(data.message, 'success');
              this.selecttype_status2 = '';
          } else if (data.status == false) {
              this.showAlert(data.message, 'error');
          }
        },

        updateWantingMoney: async function () {
          let fd = new FormData();
          fd.append('id', this.id_transaction);
          fd.append('wanting_currencie', this.maj_wanting_money);
          const data = await api.createUpdatedata('backoffice/comm-update-wanting-money', fd);
          this.dialog_wanting_money = false;
          if (data.status == true) {
            api.sendTelegram('Transaction ['+this.id_transaction+'] updated with new wanting money ('+this.maj_wanting_money+')');
              this.readAll();
              this.showAlert(data.message, 'success');
              this.selecttype_status2 = '';
          } else if (data.status == false) {
              this.showAlert(data.message, 'error');
          }
        },


        wanting_status: function (item) { // cette fonction est appelee lorsqu'on clique sur le bouton modifier du status de paiement
          this.id_transaction = item.id;
          this.dialog_status = true;
        },

        wanting_adress_update: function (item) { // cette fonction est appelee lorsqu'on clique sur le bouton modifier de l'adresse de retrait
          this.id_transaction = item.id;
          this.maj_wanting_adress = item.wanting_adresse;
          this.dialog_wanting_adress = true;
        },

        wanting_money_update: function (item) { // cette fonction est appelee lorsqu'on clique sur le bouton modifier de la monnaie de retrait
          this.id_transaction = item.id;
          this.maj_wanting_money = item.wanting_currencie;
          this.dialog_wanting_money = true;
        },

        onWantingStatusChange: function (item) { // cette fonction est appelee lorsqu'on change le status de paiement
          this.wanting_statut = this.selecttype_status;
        },


        showAlert(textmessage, txticone) {
            this.$swal.fire({
                position: 'top-end',
                icon: txticone,
                title: textmessage,
                showConfirmButton: false,
                timer: 1500
            })
        },



    },
};
</script>
